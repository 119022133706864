import { Box } from '@chakra-ui/react';
import React from 'react';

interface IMain {
  bg?: string;
  children: React.ReactNode;
}
export default function Main({ children, bg, ...rest }: IMain) {
  return (
    <Box as="main" bg={bg} p="xl" position="relative" w="full" {...rest}>
      {children}
    </Box>
  );
}
