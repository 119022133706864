import {
  Box,
  IconButton,
  Fade,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { IoCloseOutline } from 'react-icons/io5';

import Logo, { LogoVariants } from '@/components/Logo';
import { useMobileMenu } from '@/context/MobileMenuContext';

type PageProps = {
  children?: React.ReactNode;
  containBubbles?: boolean;
  navVariant?: LogoVariants;
  backgroundImage?: string;
  backgroundColor?: string;
  hasBubble?: boolean;
  illustration?: ReactNode;
};

const Aside = ({
  children,
  navVariant = 'lightYellow',
  backgroundImage = 'url(/images/employee-side-bg.png)',
  backgroundColor = '#5d5d5d',
  illustration = null,
}: PageProps) => {
  const { isMobileActive, handleToggle } = useMobileMenu();
  const isMobile = useBreakpointValue({ base: true, md: false });

  return !isMobile || isMobileActive ? (
    <Box
      as="aside"
      backgroundColor="#2B2A30"
      height="100vh"
      maxWidth="initial"
      minHeight="100vh"
      minW={['100%', '100%', '370px']}
      overflow="hidden"
      position={['fixed', 'sticky']}
      top={0}
      transition=".5s"
      w={['100%', '100%', '300px']}
      zIndex={10}
    >
      <Fade in>
        {illustration && (
          <Box
            height="100%"
            left="0"
            position="absolute"
            top="0"
            width="100%"
            zIndex="1"
          >
            {illustration}
          </Box>
        )}
        <Box
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
          backgroundSize="cover"
          bgBlendMode="multiply"
          bottom="0"
          left="0"
          position="absolute"
          right="0"
          top="0"
        />
        <Box p={['16px 16px', '42px 46px']}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            position="relative"
            zIndex="99"
          >
            <Logo variant={navVariant} w="208px" />
            <IconButton
              aria-label="toggle_button"
              color="white"
              display={['block', 'block', 'none']}
              onClick={() => handleToggle(false)}
              variant="unstyled"
              zIndex={10}
              icon={<IoCloseOutline color="inherit" size={30} />}
            />
          </Flex>
          <Box position="relative" zIndex="1">
            {children}
          </Box>
        </Box>
      </Fade>
    </Box>
  ) : null;
};
Aside.defaultProps = {
  children: null,
};

export default Aside;
