import { colors } from '../constants/colors';

const getColorBoundaries = (averageScore: number) => {
  const lowerBoundary = averageScore - 0.25 * averageScore;
  const upperBoundary = averageScore + 0.25 * (100 - averageScore);
  return { lowerBoundary, upperBoundary };
};

export const getSliderGradient = (averageScore: number) => {
  const { green, amber, red } = colors.slider;
  const { lowerBoundary, upperBoundary } = getColorBoundaries(averageScore);

  const redPart = `${red} ${lowerBoundary * 0.9}%, ${amber} ${lowerBoundary}%`;
  const amberPart = `${amber} ${lowerBoundary}%, ${amber} ${upperBoundary}%`;
  const greenPart = `${green} ${upperBoundary * 1.1}%, ${green} 100%`;

  return `linear-gradient(90deg, ${redPart}, ${amberPart}, ${greenPart})`;
};

export const getRagColor = (averageScore: number, actualScore: number) => {
  const { green, amber, red } = colors.slider;
  const { lowerBoundary, upperBoundary } = getColorBoundaries(averageScore);

  if (actualScore < lowerBoundary) {
    return red;
  }
  if (actualScore <= upperBoundary) {
    return amber;
  }
  return green;
};
