import {
  Modal as DefaultModal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  UseDisclosureProps,
  ModalFooter,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {
  wellbeingScoreToColor,
  alcoholRagThresholds,
} from '@fruitful-insights/common';
import React from 'react';

import Pin from '../Icons/Pin';
import Logo from '../Logo';

import { Button, H1, H2, H3 } from '@/elements';
import { Score, ScoreModel } from '@/generated/graphql';

const Line = styled.hr`
  margin-top: 32px;
  margin-bottom: 16px;
`;

interface IModalProps extends Partial<UseDisclosureProps> {
  children: React.ReactNode;
  title?: string;
  score?: Score | ScoreModel;
  benchmark?: number;
  hasLogo?: boolean;
  onConfirm?: () => void;
  variant?: 'green' | 'yellow' | 'red';
  loading?: boolean;
}
const Modal = ({
  title,
  score,
  isOpen,
  benchmark,
  onClose,
  children,
  hasLogo,
  onConfirm,
  variant = 'yellow',
  loading,
}: IModalProps) => {
  return (
    <DefaultModal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.500" />
      <ModalContent
        minHeight={['100%', '150px']}
        p={['md', 'lg']}
        bg="white.400"
      >
        <ModalCloseButton mt="sm" />
        <>
          <ModalHeader pb="0px">
            {hasLogo && (
              <Logo
                variant={variant === 'yellow' ? 'darkYellow' : 'darkBlue'}
              />
            )}
            {title && (
              <H1 mb="1" variant="dark">
                {title}
              </H1>
            )}

            {score && (
              <>
                <H2
                  color={wellbeingScoreToColor(
                    score.score,
                    score.scoreId === 'ECE02'
                      ? alcoholRagThresholds
                      : undefined,
                  )}
                  fontSize="xxxl"
                  mb={2}
                >
                  {Math.round(score.score)}
                </H2>
                <H3 color="text.head" m={0}>
                  Benchmark <Pin /> {benchmark}
                </H3>
              </>
            )}
            <Line />
          </ModalHeader>

          <ModalBody>{children}</ModalBody>

          {score && onClose && (
            <ModalFooter>
              <Button
                colorScheme={variant}
                mr={3}
                onClick={onClose}
                rightIcon={null}
              >
                Close
              </Button>
            </ModalFooter>
          )}
          {onConfirm && onClose && (
            <ModalFooter>
              <Button
                colorScheme={variant}
                mr={3}
                onClick={onClose}
                rightIcon={null}
              >
                Close
              </Button>
              <Button
                colorScheme={variant}
                mr={3}
                onClick={onConfirm}
                rightIcon={null}
                isLoading={loading}
              >
                Confirm
              </Button>
            </ModalFooter>
          )}
        </>
      </ModalContent>
    </DefaultModal>
  );
};

export default Modal;
