import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import Router, { useRouter } from 'next/router';

import { useAuth } from '@/context/AuthContext';
import { Button, TextField } from '@/elements';

type Props = {
  variant: 'green' | 'yellow';
};

export enum UserVariants {
  EMPLOYER = 'employer',
  EMPLOYEE = 'employee',
}

const LINKS = [
  // To select who can see the page add visibleOn array with user's role
  // If you want it to be visible on both sides do not add visibleOn property
  {
    label: 'Terms & Conditions',
    url: '/terms-and-conditions',
    visibleOn: [UserVariants.EMPLOYEE],
  },
  {
    label: 'Terms of Service',
    url: '/terms-of-service',
    visibleOn: [UserVariants.EMPLOYER],
  },
  {
    label: 'Your privacy',
    url: '/your-privacy',
  },
  { label: 'Legal', url: '/legal', visibleOn: [UserVariants.EMPLOYEE] },
];

const ModalContent = ({ variant }: Props) => {
  const { self, roles } = useAuth();
  const { push, asPath } = useRouter();

  const isTestUser = roles.find(x => (x as string) === 'TEST_USER');
  const handleLogout = () => {
    push('/auth/sign-out');
  };

  const buttonStyles = { p: '8px 0' };
  const pageVariant =
    asPath.includes(UserVariants.EMPLOYER) || asPath.includes('admin')
      ? UserVariants.EMPLOYER
      : UserVariants.EMPLOYEE;

  return (
    <>
      <Accordion allowToggle>
        {!isTestUser && (
          <AccordionItem border="none" m="1">
            <AccordionButton {...buttonStyles}>
              <Box textAlign="left">Profile & Settings</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              alignItems="center"
              justifyContent="center"
              m="2"
              paddingX={6}
              paddingY={2}
            >
              <TextField
                color="black"
                colorScheme={variant}
                errors={[]}
                id="name-input"
                isDisabled
                mb="lg"
                name="First name"
                placeholder="First name"
                value={self?.firstName || ''}
              />
              <TextField
                color="black"
                colorScheme={variant}
                errors={[]}
                id="email-input"
                isDisabled
                mb="lg"
                name="Email"
                placeholder="Email"
                value={self?.email || ''}
              />
            </AccordionPanel>
          </AccordionItem>
        )}
        {LINKS.filter(
          link => !link.visibleOn || link.visibleOn.includes(pageVariant),
        ).map(({ url, label }) => (
          <AccordionItem border="none" key={url} m="1">
            <AccordionButton
              {...buttonStyles}
              onClick={() => Router.push(`/legal/${pageVariant}${url}`)}
            >
              <Box textAlign="left">{label}</Box>
              <ChevronRightIcon />
            </AccordionButton>
          </AccordionItem>
        ))}
      </Accordion>
      <Button colorScheme={variant} marginTop="20px" onClick={handleLogout}>
        Sign out
      </Button>
    </>
  );
};

export default ModalContent;
