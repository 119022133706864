import { useEffect, useState } from 'react';
import { isMobile, isMobileSafari } from 'react-device-detect';

export default function useDeviceDetect() {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return {
    isMobile: isMobile || width <= 640,
    isMobileSafari,
  };
}
