import { defaultRagThresholds } from '../constants/thresholds';

export const shortScoreInterpretationKey = (
  scoreValue: number,
  customThresholds?: { amber: number; green: number },
) => {
  const thresholds = customThresholds ?? defaultRagThresholds;
  if (scoreValue < thresholds.amber) {
    return 'lowInterpretationShort';
  }

  if (scoreValue < thresholds.green) {
    return 'mediumInterpretationShort';
  }
  return 'highInterpretationShort';
};

export const longScoreInterpretationKey = (
  scoreValue: number,
  customThresholds?: { amber: number; green: number },
) => {
  const thresholds = customThresholds ?? defaultRagThresholds;
  if (scoreValue < thresholds.amber) {
    return 'lowInterpretation';
  }

  if (scoreValue < thresholds.green) {
    return 'mediumInterpretation';
  }
  return 'highInterpretation';
};

export const actionPlanKey = (
  scoreValue: number,
  customThresholds?: { amber: number; green: number },
) => {
  const thresholds = customThresholds ?? defaultRagThresholds;
  if (scoreValue < thresholds.amber) {
    return 'lowActionPlan';
  }

  if (scoreValue < thresholds.green) {
    return 'mediumActionPlan';
  }
  return '';
};
