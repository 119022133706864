import { createIcon } from '@chakra-ui/icons';

const Pin = createIcon({
  displayName: 'PinIcon',
  viewBox: '0 0 14 16',
  path: (
    <svg
      height="16px"
      version="1.1"
      viewBox="0 0 14 16"
      width="14px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>10.027 Employee_Pin</title>
      <g
        fill="none"
        fillRule="evenodd"
        id="10.027-Employee_Pin"
        stroke="none"
        strokeWidth="1"
      >
        <path
          d="M7.25013806,2 C4.35068239,2 2,4.35068239 2,7.25041417 C2,8.89190202 2.8772089,10.2213829 3.93209609,11.3189295 C4.69540865,12.1135808 7.25013806,14.4250552 7.25013806,14.4250552 C7.25013806,14.4250552 10.0136084,11.9214066 10.2603148,11.6748383 C11.4232999,10.5111628 12.5,9.02112259 12.5,7.25041417 C12.4998619,4.35068239 10.1493176,2 7.25013806,2"
          fill="#4A4A4A"
          id="Fill-1"
          stroke="#4A4A4A"
          strokeWidth="2"
          transform="translate(7.250000, 8.212528) scale(1, -1) translate(-7.250000, -8.212528) "
        />
      </g>
    </svg>
  ),
});

export default Pin;
