import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import FormWrapper from '@/components/Form/FormWrapper';
import Modal from '@/components/Modal';
import { Button, TextArea, TextField } from '@/elements';
import useYupValidationResolver from '@/hooks/useYupValidationResolver';

const SupportModal = ({
  isOpen,
  onClose,
  variant,
}: {
  isOpen: boolean;
  onClose: () => void;
  variant: 'green' | 'yellow';
}) => {
  const validationSchema = useMemo(
    () =>
      yup.object({
        subject: yup.string().required('Please enter a subject'),
      }),
    [],
  );
  const resolver = useYupValidationResolver(validationSchema);
  const { handleSubmit, register, control, errors } = useForm({ resolver });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Contact us">
      <FormWrapper
        fullWidth
        onSubmit={handleSubmit(values => {
          window.open(
            `mailto:support@fruitfulinsights.co.uk?subject=${encodeURIComponent(
              values.subject,
            )}&body=${values.body}`,
          );
        })}
      >
        <TextField
          color="black"
          colorScheme={variant}
          errors={errors}
          id="subject-input"
          mb="lg"
          name="subject"
          placeholder="Subject"
          register={register}
        />
        <TextArea
          color="black"
          colorScheme={variant}
          register={register}
          errors={errors}
          id="body-input"
          minLength={3}
          name="body"
          placeholder="Content"
          rows={4}
        />
        <Button
          alignSelf="flex-end"
          colorScheme={variant}
          marginTop="24px"
          type="submit"
        >
          Submit
        </Button>
      </FormWrapper>
    </Modal>
  );
};

export default SupportModal;
