const downloadFile = ({
  url,
  fileName = 'file',
  ext = '.pdf',
  overrideCurrentPage = false,
}: {
  url: string;
  fileName?: string;
  ext?: string;
  overrideCurrentPage?: boolean;
}): void => {
  const link = document.createElement('a');

  link.download = `${fileName}${ext}`;
  link.href = url;
  link.target = !overrideCurrentPage ? '_blank' : '';
  link.rel = 'noopener';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadFile;
