import { Box, Text, Flex } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import React from 'react';

import SectionContent from '../SectionContent';
import SectionRow from '../SectionRow';

import Logo, { LogoVariants } from '@/components/Logo';
import { Link } from '@/elements/links';

export type FooterVariant =
  | 'employeeLight'
  | 'employeeDark'
  | 'employerLight'
  | 'employerDark';

interface IFooter {
  variant: FooterVariant;
  logo?: 'lightYellow' | 'darkYellow' | 'lightBlue' | 'darkBlue';
}

const Footer = ({ variant }: IFooter) => {
  const theme = useTheme();

  const getVariant = (
    value: FooterVariant,
  ): {
    text: string;
    logo: LogoVariants;
    border: string;
  } => {
    switch (value) {
      case 'employeeLight':
        return {
          text: theme.colors.text.light,
          logo: 'lightYellow',
          border: theme.colors.yellow[400],
        };
      case 'employeeDark':
        return {
          text: theme.colors.text.dark,
          logo: 'darkYellow',
          border: theme.colors.yellow[400],
        };
      case 'employerLight':
        return {
          text: theme.colors.text.light,
          logo: 'lightBlue',
          border: theme.colors.green[500],
        };
      case 'employerDark':
        return {
          text: theme.colors.text.dark,
          logo: 'darkBlue',
          border: theme.colors.green[500],
        };
      default:
        return {
          text: theme.colors.text.dark,
          logo: 'darkYellow',
          border: theme.colors.yellow[400],
        };
    }
  };

  const colorTheme = getVariant(variant);

  return (
    <SectionRow>
      <SectionContent>
        <Flex
          alignItems="center"
          borderBottomColor={colorTheme.border}
          borderBottomWidth="2px"
          flexWrap="wrap"
          justifyContent="space-between"
          py="8px"
        >
          <Box marginBottom="12px" width="290px">
            <Logo variant={colorTheme.logo} />
            <Text
              color={colorTheme.text}
              fontSize="11px"
              marginTop="9px"
              maxWidth="20rem"
            >
              Helping transform the workforce productivity of businesses through
              actionable health and wellbeing insight.
            </Text>
          </Box>
          <Flex flexWrap="wrap" justifyContent="start">
            <Flex flexWrap="wrap" justifyContent="start" mb="8px">
              <Link
                color={colorTheme.text}
                fontSize="xs"
                href="https://www.fruitfulinsights.co.uk/about-fruitful"
                marginBottom="8px"
                marginRight="31px"
              >
                About Fruitful Insights
              </Link>
              <Link
                color={colorTheme.text}
                fontSize="xs"
                href="https://www.fruitfulinsights.co.uk/our-solution"
                marginBottom="8px"
                marginRight="31px"
              >
                Our Services
              </Link>
              <Link
                color={colorTheme.text}
                fontSize="xs"
                href="https://www.fruitfulinsights.co.uk/"
                marginBottom="8px"
                marginRight="31px"
              >
                Contact
              </Link>
            </Flex>
            <Text color={colorTheme.text} fontSize="11px">
              ©2020 Fruitful Insights All rights reserved
            </Text>
          </Flex>
        </Flex>
      </SectionContent>
    </SectionRow>
  );
};
Footer.defaultProps = {
  variant: 'tertiary',
  logo: 'darkYellow',
};

export default Footer;
