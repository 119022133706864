import {
  Flex,
  Modal,
  Box,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { LoadingDots } from '@/components/LoadingSpinner';
import Logo from '@/components/Logo';
import { Button } from '@/elements';
import {
  ReportModel,
  ReportStatus,
  useAssessmentQuery,
  useEmployeeReport,
  useGenerateEmployeeReport,
} from '@/generated/graphql';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import downloadFile from '@/utils/downloadFile';

const PrintReportModal: FC<{
  assessmentId: string;
  isOpen: boolean;
  onClose: () => void;
}> = ({ assessmentId, isOpen, onClose }) => {
  const [report, setReport] = useState<ReportModel | undefined>();
  const [error, setError] = useState('');
  const {
    data,
    loading: fetchingAssessment,
    startPolling,
    stopPolling,
  } = useAssessmentQuery({
    variables: {
      id: assessmentId,
    },
  });
  const [generateReport] = useGenerateEmployeeReport();
  const { refetch } = useEmployeeReport({ skip: true });
  const { isMobileSafari } = useDeviceDetect();
  const downloadReport = useCallback(async () => {
    setError('');
    const res = await refetch({ id: report.id });
    const url = res?.data.employeeReport.downloadUrl;

    if (url) {
      downloadFile({ url, overrideCurrentPage: isMobileSafari });
    } else {
      setError(
        'There was a problem downloading your report. Please try again or contact support',
      );
    }
  }, [isMobileSafari, refetch, report]);

  useEffect(() => {
    const fetchedReport = data?.assessment.assessment.report as ReportModel;

    if (fetchedReport?.status !== ReportStatus.Requested) {
      stopPolling();
    }
    setReport(fetchedReport);
  }, [data]);

  useEffect(() => {
    const checkReport = async () => {
      const requestedMoreThan10MinsAgo =
        !report?.requestedAt ||
        (report?.status === ReportStatus.Requested &&
          moment(report?.requestedAt).add(10, 'minute').isBefore(moment()));

      if (!report || requestedMoreThan10MinsAgo) {
        const result = await generateReport({
          variables: { options: { assessmentId: Number(assessmentId) } },
        });

        if (result?.data.generateEmployeeReport.error) {
          setError(result?.data.generateEmployeeReport.error);
        }
        if (result?.data.generateEmployeeReport.report) {
          setReport(result?.data.generateEmployeeReport.report as ReportModel);
        }
      }
      if (!report || report?.status === ReportStatus.Requested) {
        startPolling(1000 * 5);
      }
    };
    if (isOpen) {
      checkReport();
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    onClose();
    stopPolling();
  }, []);

  const reportReady = report?.gcsFile?.url;

  const isLoading =
    fetchingAssessment ||
    !reportReady ||
    report?.status === ReportStatus.Requested;
  const [newBg, setNewBg] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setNewBg(true), 3000);
  }, [newBg]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay bg="blackAlpha.500" />
      {isLoading ? (
        <ModalContent
          style={{
            width: 500,
            height: 500,
            ...(!newBg && { backgroundImage: "url('/images/bg.png')" }),
            backgroundSize: '500px 500px',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <ModalBody
            style={{
              width: 500,
              height: 500,
              padding: 0,
              backgroundColor: `${
                newBg ? 'rgba(255,255,255)' : 'rgba(0,0,0,0.5)'
              }`,
            }}
          >
            {newBg ? (
              <Box style={{ height: '100%' }}>
                <Box
                  style={{
                    height: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: 66,
                  }}
                >
                  <Logo variant="darkYellow" w={244} />
                  <Box style={{ marginTop: 111 }}>
                    <LoadingDots />
                  </Box>
                </Box>
                <Box
                  style={{
                    height: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <ModelQuotes />
                  <Text
                    color="yellow.400"
                    fontSize="18px"
                    fontStyle="italic"
                    letterSpacing="-0.25px"
                    mb={12}
                    textAlign="center"
                  >
                    Your Fruitful Insights report is being generated
                  </Text>
                </Box>
              </Box>
            ) : (
              <Box style={{ height: '100%' }}>
                <Box
                  style={{
                    height: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 170,
                  }}
                >
                  <LoadingDots />
                </Box>
                <Box
                  style={{
                    height: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Logo variant="lightYellow" w={244} />
                  <Text
                    color="white.400"
                    fontSize="18px"
                    fontStyle="italic"
                    letterSpacing="-0.25px"
                    maxWidth={365}
                    mt={9}
                    textAlign="center"
                  >
                    We’re just crunching the numbers… your Fruitful Insights
                    report is being generated
                  </Text>
                </Box>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      ) : (
        <ModalContent
          style={{
            width: 500,
            height: 500,
          }}
        >
          <ModalBody
            style={{
              width: 500,
              height: 500,
              backgroundColor: 'rgba(255,255,255)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Logo variant="darkYellow" />
            <Box>
              {report?.gcsFile?.url && (
                <Flex alignItems="center" justifyContent="center">
                  <Text
                    color="text.head"
                    fontSize="base"
                    fontWeight="medium"
                    mr="sm"
                  >
                    Your report is ready:
                  </Text>
                  <Button
                    color="yellow.400"
                    onClick={downloadReport}
                    variant="link"
                  >
                    Download
                  </Button>
                </Flex>
              )}
              {(report?.status === ReportStatus.Errored || error) && (
                <Flex alignItems="center" color="red" justifyContent="center">
                  {report?.error || error}
                </Flex>
              )}
            </Box>
            <Button mr={3} onClick={handleClose} rightIcon={null}>
              Close
            </Button>
          </ModalBody>
        </ModalContent>
      )}
    </Modal>
  );
};

export default PrintReportModal;

const quotes = [
  {
    quote:
      '”You have to act as if it were possible to radically transform the world. And you have to do it all the time.”',
    author: '– Angela Davis',
  },
  {
    quote:
      '“If you love life, don’t waste time, for time is what life is made up of.”',
    author: '– Bruce Lee',
  },
  {
    quote: "“This is not a moment, it's the movement.”",
    author: '– Lin-Manuel Miranda',
  },
  {
    quote:
      '“How we spend our days is, of course, how we spend our lives. What we do with this hour, and that one, is what we are doing.”',
    author: '– Annie Dillard ',
  },
  { quote: '“Always in motion is the future.”', author: '– Yoda' },
];

const ModelQuotes = () => {
  const [index, setIndex] = useState<number>(0);
  useEffect(() => {
    if (index === quotes.length - 1) {
      setIndex(0);
    }

    const interval = setInterval(() => {
      setIndex(prevState => prevState + 1);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 356,
      }}
    >
      <Text
        fontSize="18px"
        fontStyle="italic"
        letterSpacing="-0.25px"
        textAlign="center"
      >
        {quotes[index]?.quote}
      </Text>
      <Text fontSize="18px" fontWeight="bold" mb={15} mt={5} textAlign="center">
        {quotes[index]?.author}
      </Text>
    </Box>
  );
};
