import { colors } from '../constants/colors';
import { defaultRagThresholds } from '../constants/thresholds';

export const wellbeingScoreToColor = (
  scoreValue: number,
  customThresholds?: { amber: number; green: number },
) => {
  const thresholds = customThresholds ?? defaultRagThresholds;
  if (scoreValue < thresholds.amber) {
    return colors.rag.red;
  }

  if (scoreValue < thresholds.green) {
    return colors.rag.amber;
  }

  return colors.rag.green;
};
