import { Box, Icon, useTheme, Link } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { CSSProperties, FC, ReactNode } from 'react';

import { TextButton } from '@/elements';

export const NavContainer = ({ children }) => (
  <Box m={3} mt={4} position="relative">
    {children}
  </Box>
);

interface IContainer {
  selected?: boolean;
  selectedColor?: string;
  disabled?: boolean;
}

const Container = styled.div<IContainer>`
  a {
    // Applying the color directly because foundations/colors doesn't contain #00D3C5 on the color objects
    color: ${p => (p.selected ? p.selectedColor : 'white')};
    display: block;
    margin-bottom: 18px;
    font-weight: 500;
    opacity: ${props => (props.disabled ? 0.4 : '')};
    pointer-events: ${props => (props.disabled ? 'none' : '')};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const getVariant = (variant, theme) => {
  switch (variant) {
    case 'green':
      return theme.colors.employee.green;
    case 'yellow':
      return theme.colors.employee.yellow;
    default:
      return theme.colors.employee.yellow;
  }
};

interface INavLink {
  href: string;
  children: React.ReactNode;
  selected?: boolean;
  variant: 'green' | 'yellow';
  isDisabled?: boolean;
  style?: CSSProperties;
}

export const NavLinkWithIcon: FC<{
  icon: any;
  href?: string;
  variant: 'green' | 'yellow';
  selected?: boolean;
  isDisabled?: boolean;
  children?: ReactNode;
  onClick?: () => void;
}> = ({ icon, href, children, variant, selected, onClick, isDisabled }) => {
  const theme = useTheme();
  const color = selected ? getVariant(variant, theme) : 'white';

  return (
    <Box
      alignItems="center"
      display="flex"
      mb="16px"
      position="relative"
      zIndex="10"
    >
      <Icon as={icon} color="white" mr="10px" />
      {onClick ? (
        <TextButton
          color="white"
          fontSize="12px"
          fontWeight="normal"
          isDisabled={isDisabled}
          onClick={onClick}
          selected={selected}
        >
          {children}
        </TextButton>
      ) : (
        <Link color={color} fontSize="12px" href={href} variant={variant}>
          {children}
        </Link>
      )}
    </Box>
  );
};

export const NavLink = ({
  href,
  children,
  selected,
  variant,
  isDisabled,
  style = {},
}: INavLink) => {
  const theme = useTheme();

  const color = getVariant(variant, theme);

  return (
    <Container
      disabled={isDisabled}
      selected={selected}
      selectedColor={color}
      style={style}
    >
      <Link href={href}>{children}</Link>
    </Container>
  );
};
