const colors = {
  yellow: {
    50: '#FFF8E5',
    100: '#FFEAB8',
    200: '#FFDD8A',
    300: '#FFCF5C',
    400: '#FFC22E',
    500: '#FFB500',
    600: '#CC9100',
    700: '#996C00',
    800: '#664800',
    900: '#332400',
  },
  red: {
    50: '#FCE9F0',
    100: '#F5C1D6',
    200: '#EF9ABC',
    300: '#E972A2',
    400: '#E24B87',
    450: '#DD2971',
    500: '#DC236D',
    600: '#B01C57',
    700: '#841541',
    800: '#580E2C',
    900: '#2C0716',
  },
  blue: {
    50: '#EAF2FB',
    100: '#C4DCF2',
    200: '#9EC5EA',
    300: '#79AEE2',
    400: '#5398DA',
    500: '#2D81D2',
    600: '#2467A8',
    700: '#1B4D7E',
    750: '#1B1D1F',
    800: '#123454',
    900: '#091A2A',
  },
  purple: {
    50: '#F0EEF6',
    100: '#D4D0E7',
    200: '#B8B1D8',
    300: '#9C93C8',
    400: '#8074B9',
    500: '#6556AA',
    550: '#433971',
    600: '#504488',
    700: '#3C3366',
    800: '#282244',
    900: '#141122',
  },
  gray: {
    50: '#FBFBFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#0c0b0b',
  },
  green: {
    50: '#E5FFFD',
    100: '#B8FFFA',
    200: '#8AFFF7',
    300: '#5CFFF4',
    400: '#2EFFF1',
    500: '#00D3C5',
    600: '#00CCBE',
    700: '#00998F',
    800: '#00665F',
    850: '#1a6968',
    900: '#003330',
  },
  grey: { 400: '#FBFBFC', 500: '#F5F5F5' },
  white: { 400: '#FFFFFF' },
  text: {
    dark: '#4A4A4A',
    light: '#fff',
    head: '#2B2A30',
  },
  rag: {
    amber: '#FFC22E',
    red: '#EA3524',
    green: '#38803D',
    grey: '#BAAFAD',
  },
  score: {
    red: '#EA3524',
    yellow: '#FF731A',
    green: '#38803D',
  },
  employee: {
    green: '#00D3C5',
    greenLight: '#CCF6F3',
    yellow: '#FFC027',
  },
  slider: {
    red: '#FF4F4F',
    amber: '#FFD03A',
    green: '#27FF3A',
  },
};

export { colors };
