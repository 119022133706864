import { Box, BoxProps } from '@chakra-ui/react';
import React, { FC } from 'react';

type FormWrapperProps = BoxProps & {
  children: React.ReactNode;
  onSubmit: (x?: any) => any;
  fullWidth?: boolean;
  method?: string;
};

const FormWrapper: FC<FormWrapperProps> = ({
  children,
  onSubmit,
  fullWidth,
  method,
  ...rest
}: FormWrapperProps) => (
  <Box
    {...rest}
    display="flex"
    flexDirection="column"
    maxW={fullWidth ? '100%' : '2xl'}
  >
    <form method={method} onSubmit={onSubmit}>
      {children}
    </form>
  </Box>
);
export default FormWrapper;
