import { useTheme, chakra, Box } from '@chakra-ui/react';
import React from 'react';

import useAutoHideHeader from './useAutoHideHeader';

const HeaderContainer = chakra(Box, {
  baseStyle: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 'overlay',
    display: 'flex',
    alignItems: 'center',
  },
});

const Logo = chakra(Box);

export default function Header() {
  const theme = useTheme();
  const navHeight = theme.header.height;

  const { isAtTop, nav } = useAutoHideHeader({
    navHeight,
  });

  return (
    <HeaderContainer
      backgroundColor="yellow.400"
      boxShadow={!isAtTop && 'md'}
      height={`${navHeight}px`}
      p="md"
      ref={nav as any}
    >
      <Logo>Fruitful Insights</Logo>
    </HeaderContainer>
  );
}
